import { render, staticRenderFns } from "./UserDetailsDriverDocuments.vue?vue&type=template&id=1aaefe68&scoped=true&"
import script from "./UserDetailsDriverDocuments.vue?vue&type=script&lang=js&"
export * from "./UserDetailsDriverDocuments.vue?vue&type=script&lang=js&"
import style0 from "./UserDetailsDriverDocuments.vue?vue&type=style&index=0&id=1aaefe68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aaefe68",
  null
  
)

export default component.exports